import React, { useState, useEffect  } from "react";
import { Link, useLocation  } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNewspaper,
  faUser,
  faAngleDown,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNewsDropdownOpen, setIsNewsDropdownOpen] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({ news: false, user: false });
  const location = useLocation();


  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleNewsDropdown = () => {
    setIsNewsDropdownOpen(!isNewsDropdownOpen);
    setIsUserDropdownOpen(false); // Đóng dropdown user nếu đang mở
  };

  const toggleUserDropdown = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
    setIsNewsDropdownOpen(false); // Đóng dropdown news nếu đang mở
  };
  const toggleDropdown = (type) => {
    setDropdownOpen((prev) => ({ ...prev, [type]: !prev[type] }));
  };
  useEffect(() => {
    // Open dropdowns based on current location
    setDropdownOpen({
      news: location.pathname.startsWith("/manager/news"),
      user: location.pathname.startsWith("/manager/user"),
    });
  }, [location]);
  
  return (
    <div className="flex">
      <button
        onClick={toggleSidebar}
        aria-controls="sidebar-multi-level-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span className="sr-only">Open sidebar</span>
        {/* Icon for sidebar toggle */}
      </button>

      <aside
        id="sidebar-multi-level-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
          <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse py-3">
            <img
              src="/logo.png"
              className="h-12"
              alt="baomoitaybac logo"
            />
          </Link>
          <ul className="space-y-2 font-medium">
            <li>
              <Link
                to="/manager/categories"
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-blue-400 hover:text-gray-600 dark:hover:bg-gray-700 group ${
                  location.pathname === "/manager/categories" ? "bg-blue-700 text-white" : ""
                }`}
              >
                <FontAwesomeIcon icon={faLayerGroup} />
                <span className="ms-3">Chuyên mục</span>
              </Link>
            </li>
            <li>
              <button
                type="button"
                className={`flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-blue-400 hover:text-gray-600 dark:text-white dark:hover:bg-gray-700 ${
                  dropdownOpen.news ? "bg-blue-700 text-white" : ""
                }`}
                onClick={() => toggleDropdown("news")}
              >
                <FontAwesomeIcon icon={faNewspaper} />
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Bài viết</span>
                <FontAwesomeIcon icon={faAngleDown} />
              </button>
              {dropdownOpen.news && (
                <ul className="py-2 space-y-2 bg-gray-200 rounded-b">
                  <li className="px-3">
                    <Link
                      to="/manager/news/add"
                      className={`flex items-center w-full px-4 py-1 transition duration-75 group hover:bg-blue-400 hover:text-gray-600 dark:text-white dark:hover:bg-gray-700 ${
                        location.pathname === "/manager/news/add" ? "bg-blue-700 text-white" : ""
                      }`}
                    >
                      Thêm bài viết
                    </Link>
                  </li>
                  <li className="px-3">
                    <Link
                      to="/manager/news"
                      className={`flex items-center w-full px-4 py-1 transition duration-75 group hover:bg-blue-400 hover:text-gray-600 dark:text-white dark:hover:bg-gray-700 ${
                        location.pathname === "/manager/news" ? "bg-blue-700 text-white" : ""
                      }`}
                    >
                      Danh sách
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <button
                type="button"
                className={`flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-blue-400 hover:text-gray-600 dark:text-white dark:hover:bg-gray-700 ${
                  dropdownOpen.user ? "bg-blue-700 text-white" : ""
                }`}
                onClick={() => toggleDropdown("user")}
              >
                <FontAwesomeIcon icon={faUser} />
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">User</span>
                <FontAwesomeIcon icon={faAngleDown} />
              </button>
              {dropdownOpen.user && (
                <ul className="py-2 space-y-2 bg-gray-200 rounded-b">
                  <li className="px-3">
                    <Link
                      to="/manager/user/add"
                      className={`flex items-center w-full px-4 py-1 transition duration-75 group hover:bg-blue-400 hover:text-gray-600 dark:text-white dark:hover:bg-gray-700 ${
                        location.pathname === "/manager/user/add" ? "bg-blue-700 text-white" : ""
                      }`}
                    >
                      Thêm người dùng
                    </Link>
                  </li>
                  <li className="px-3">
                    <Link
                      to="/manager/user"
                      className={`flex items-center w-full px-4 py-1 transition duration-75 group hover:bg-blue-400 hover:text-gray-600 dark:text-white dark:hover:bg-gray-700 ${
                        location.pathname === "/manager/user" ? "bg-blue-700 text-white" : ""
                      }`}
                    >
                      Danh sách
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </div>
      </aside>

      <div className="flex-grow p-6">
        <h1 className="text-2xl font-bold">Nội dung chính</h1>
      </div>
    </div>
  );
};

export default Sidebar;
