import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NewsManager from "./pages/manager/news/newsManager";
import AddNews from "./pages/manager/news/addNews";
import EditNews from "./pages/manager/news/editNews";
import CategoryManagement from "./pages/manager/categories/index";
import Login from "./pages/auth/login.js";
import Register from "./pages/auth/register.js";
import AboutUs from "./pages/home/aboutUs.js";
import HomePage from "./pages/home/index.js";
import Layout from "./components/layout"; 
import Logout from "./pages/auth/logout.js";
import ChangePassword from "./pages/auth/changePassword.js"

function App() {
  return (
    <Router>
      <Routes>
        {/* Các route không dùng Layout */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/ve-chung-toi" element={<AboutUs />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/change-password" element={<ChangePassword/>} />
        {/* Các route dùng Layout */}
        <Route
          path="/manager/*"
          element={
            <Layout>
              <div className="container mx-auto">
                <Routes>
                  <Route path="news" element={<NewsManager />} />
                  <Route path="news/add" element={<AddNews />} />
                  <Route path="news/edit/:id" element={<EditNews />} />
                  <Route path="categories" element={<CategoryManagement />} />
                </Routes>
              </div>
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
