import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faEdit,
  faPlus,
  faAnglesLeft,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";

const CategoryManagement = () => {
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [parentId, setParentId] = useState("");
  const [description, setDescription] = useState("");
  const [editId, setEditId] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  // Lấy danh sách chuyên mục từ API
  const fetchCategories = async () => {
    const res = await axios.get("https://api.baomoitaybac.com/categories.php");
    setCategories(res.data);
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  const indexOfLastCategory = currentPage * itemsPerPage;
  const indexOfFirstCategory = indexOfLastCategory - itemsPerPage;
  const currentCategories = categories.slice(
    indexOfFirstCategory,
    indexOfLastCategory
  );
  const totalPages = Math.ceil(categories.length / itemsPerPage);
  // Thêm chuyên mục mới
  const addCategory = async (e) => {
    e.preventDefault();
    try {
      // Gửi yêu cầu thêm chuyên mục mới
      await axios.post(
        "https://api.baomoitaybac.com/categories.php",
        {
          name: name,
          parent_id: parentId || null,
          description: description,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      // Lấy lại danh sách chuyên mục sau khi thêm thành công
      await fetchCategories();
      resetForm(); // Reset lại form để chuẩn bị cho lần nhập mới
    } catch (error) {
      console.error("Lỗi khi thêm chuyên mục:", error);
      alert("Có lỗi xảy ra khi thêm chuyên mục!");
    }
  };

  // Kiểm tra xem có phải chuyên mục cha không
  const isParentCategory = (id) => {
    return categories.some((cat) => cat.parent_id === id);
  };

  // Hiển thị modal confirm
  const showDeleteModal = (id) => {
    const isParent = isParentCategory(id); // Kiểm tra nếu là chuyên mục cha
    if (isParent) {
      setErrorMessage("Đây là chuyên mục cha, không thể xóa.");
      setConfirmDelete(true);
    } else {
      setDeleteId(id);
      setConfirmDelete(true);
      setErrorMessage(""); // Reset thông báo lỗi
    }
  };

  // Đóng modal confirm
  const closeDeleteModal = () => {
    setConfirmDelete(false);
    setDeleteId(null);
  };
  // Xóa chuyên mục
  const deleteCategory = async () => {
    try {
      await axios.delete("https://api.baomoitaybac.com/categories.php", {
        data: { id: deleteId },
        headers: { "Content-Type": "application/json" },
      });
      fetchCategories(); // Cập nhật lại danh sách sau khi xóa
      closeDeleteModal(); // Đóng modal
    } catch (error) {
      console.error("Lỗi khi xóa chuyên mục:", error);
    }
  };

  // Chọn chuyên mục để sửa
  const editCategory = (category) => {
    setName(category.name);
    setParentId(category.parent_id);
    setDescription(category.description);
    setEditId(category.id);
  };

  // Cập nhật chuyên mục
  const updateCategory = async (e) => {
    e.preventDefault();

    try {
      await axios.put(
        "https://api.baomoitaybac.com/categories.php",
        {
          id: editId,
          name,
          parent_id: parentId || null,
          description,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      fetchCategories(); // Gọi lại để cập nhật danh sách chuyên mục
      resetForm(); // Xóa form
    } catch (error) {
      console.error("Lỗi khi cập nhật chuyên mục:", error);
    }
  };

  // Reset form
  const resetForm = () => {
    setName("");
    setParentId("");
    setDescription("");
    setEditId(null);
  };
  // Phân trang
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={`mx-1 p-2 rounded-full w-8 h-8 flex items-center justify-center ${
            currentPage === i
              ? "bg-blue-500 text-white"
              : "bg-gray-300 hover:bg-gray-400"
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="max-w-[85rem] mx-auto p-4">
      <div className="p-4 rounded shadow bg-gray-100 mb-6">
        <h1 className="uppercase text-blue-900 font-bold mb-4 text-center">
          Thêm chuyên mục mới
        </h1>
        <form
          onSubmit={editId ? updateCategory : addCategory}
          className=" grid grid-cols-8 gap-2"
        >
          <div className="mb-4 col-span-2">
            <label className="block text-gray-700" htmlFor="name">
              Tên chuyên mục
            </label>
            <input
              type="text"
              id="name"
              className="mt-1 p-2 border rounded w-full"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Tên chuyên mục"
              required
            />
          </div>
          <div className="mb-4 col-span-2">
            <label className="block text-gray-700" htmlFor="parentId">
              Chọn chuyên mục cha
            </label>
            <select
              id="parentId"
              className="mt-1 p-2 border rounded w-full"
              value={parentId}
              onChange={(e) => setParentId(e.target.value)}
            >
              <option value="">Không có chuyên mục cha</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4 col-span-2">
            <label className="block text-gray-700" htmlFor="description">
              Mô tả
            </label>
            <input
              id="description"
              className="mt-1 p-2 border rounded w-full border-gray-600 focus:outline-blue-600 focus:border-blue-600"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Mô tả"
            />
          </div>
          <div className="flex justify-start items-end mb-4 gap-1">
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
            >
              {editId ? (
                "Cập nhật"
              ) : (
                <>
                  Thêm <FontAwesomeIcon icon={faPlus} />
                </>
              )}
            </button>
            {editId && (
              <button
                type="button"
                onClick={resetForm}
                className="bg-gray-300 text-gray-800 p-2 rounded ml-2 hover:bg-gray-400"
              >
                Hủy
              </button>
            )}
          </div>
        </form>
      </div>
      <h1 className="uppercase text-blue-900 font-bold mb-4 text-center">
        Danh sách chuyên mục
      </h1>
      <table className="min-w-full bg-white border rounded shadow">
        <thead className="bg-blue-900">
          <tr>
            <th className="py-2 px-4 border text-left text-white">ID</th>
            <th className="py-2 px-4 border text-left text-white">Tên</th>
            <th className="py-2 px-4 border text-left text-white">
              Chuyên Mục Cha
            </th>
            <th className="py-2 px-4 border text-left text-white">
              Chuyên Mục Con
            </th>
            <th className="py-2 px-4 border text-left text-white">Mô Tả</th>
            <th className="py-2 px-4 border text-left text-white">Hành Động</th>
          </tr>
        </thead>
        <tbody>
          {currentCategories.map((cat) => {
            // Tìm chuyên mục cha dựa trên parent_id
            const parentCategory = categories.find(
              (parent) => parent.id === cat.parent_id
            );

            // Lấy các chuyên mục con
            const childCategories = categories
              .filter((category) => category.parent_id === cat.id)
              .map((child) => child.name)
              .join(", "); // Liệt kê tên các chuyên mục con

            return (
              <tr key={cat.id} className="hover:bg-gray-100">
                <td className="py-2 px-4 border">{cat.id}</td>
                <td className="py-2 px-4 border">{cat.name}</td>
                <td className="py-2 px-4 border">
                  {parentCategory ? parentCategory.name : "Không có"}
                </td>
                <td className="py-2 px-4 border">
                  {childCategories || "Không có"}
                </td>
                <td className="py-2 px-4 border">{cat.description}</td>
                <td className="py-2 px-4 border flex justify-center items-center gap-2">
                  <button
                    onClick={() => editCategory(cat)}
                    className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button
                    onClick={() => showDeleteModal(cat.id)}
                    className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Nút phân trang */}
      <div className="flex justify-center mt-4">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="bg-gray-300 p-2 rounded-full hover:bg-gray-400 w-8 h-8 flex items-center justify-center"
        >
          <FontAwesomeIcon icon={faAnglesLeft} />
        </button>

        {renderPageNumbers()}

        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className="bg-gray-300 p-2 rounded-full hover:bg-gray-400 w-8 h-8 flex items-center justify-center"
        >
          <FontAwesomeIcon icon={faAnglesRight} />
        </button>
      </div>

      {confirmDelete && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-md">
            {errorMessage ? (
              <p className="mb-4 text-red-600">{errorMessage}</p>
            ) : (
              <p className="mb-4">Bạn có chắc chắn muốn xóa chuyên mục này?</p>
            )}
            <div className="flex justify-end">
              {!errorMessage && (
                <button
                  onClick={deleteCategory}
                  className="bg-red-500 text-white px-4 py-2 rounded mr-2 hover:bg-red-600"
                >
                  Xóa
                </button>
              )}
              <button
                onClick={closeDeleteModal}
                className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
              >
                Hủy
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryManagement;
