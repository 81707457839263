// Layout.js
import React from 'react';
import Header from './header'; // Thay đổi đường dẫn nếu cần
import Sidebar from './sidebar'; // Thay đổi đường dẫn nếu cần

const Layout = ({ children }) => {
    return (
        <div className="flex">
      <Sidebar />
      <div className="flex-grow ml-8">
        <Header />
        <main className="p-4">
          {children}
        </main>
      </div>
    </div>
    );
};

export default Layout;
