// src/pages/auth/login/index.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const [identifier, setIdentifier] = useState(""); // State cho email hoặc số điện thoại
  const [password, setPassword] = useState(""); // State cho password
  const [showPassword, setShowPassword] = useState(false); // Ẩn/Hiện password
  const [error, setError] = useState(""); // State cho thông báo lỗi
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  useEffect(() => {
    // Kiểm tra nếu đã đăng nhập rồi thì chuyển hướng về trang chủ
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const savedIdentifier = localStorage.getItem("identifier");
    if (savedIdentifier) {
      setIdentifier(savedIdentifier);
      setRememberMe(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://api.baomoitaybac.com/api/auth/login.php",
        {
          email_or_phone: identifier, // Cho phép dùng email hoặc số điện thoại
          password,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.data.user) {
        const user = response.data.user;
        localStorage.setItem("token", response.data.token);
        if (rememberMe) {
          localStorage.setItem("identifier", identifier);
        } else {
          localStorage.removeItem("identifier");
        }
        
        // Điều hướng theo role
        if (user.role === "admin" || user.role === "staff") {
          navigate("/manager/news");
        } else {
          navigate("/");
        }
      } else {
        setError("Email/Số điện thoại hoặc mật khẩu không chính xác.");
      }
    } catch (error) {
      console.error(error);
      setError("Có lỗi xảy ra. Vui lòng thử lại!");
    }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 min-h-screen flex items-center justify-center">
      <div className="w-full max-w-md bg-white rounded-lg shadow dark:border dark:bg-gray-800 dark:border-gray-700">
        <Link to="/" className="flex items-center text-2xl font-semibold text-gray-900 dark:text-white">
          <img className="h-20 py-2 mx-auto" src="/logo.png" alt="logo bao moi tay bac" />
        </Link>
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-xl font-bold leading-tight text-gray-900 md:text-2xl dark:text-white">
            Đăng nhập tài khoản của bạn
          </h1>
          {error && <p className="text-red-500">{error}</p>}
          <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="identifier" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Email hoặc số điện thoại
              </label>
              <input
                type="text"
                id="identifier"
                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Nhập email hoặc số điện thoại"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                required
              />
            </div>
            <div className="relative">
              <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Mật khẩu
              </label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="••••••••"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className="absolute right-3 top-1/2 mt-1 text-gray-600 dark:text-gray-400"
                onClick={() => setShowPassword(!showPassword)}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </button>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    type="checkbox"
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                </div>
                <label htmlFor="remember" className="ml-2 text-sm text-gray-500 dark:text-gray-300">
                  Ghi nhớ
                </label>
              </div>
              <a href="#" className="text-sm text-primary-600 hover:underline dark:text-primary-500">
                Quên mật khẩu?
              </a>
            </div>
            <button
              type="submit"
              className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700"
            >
              Đăng nhập
            </button>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Bạn chưa có tài khoản?{" "}
              <Link to="/register" className="text-primary-600 hover:underline dark:text-primary-500">
                Đăng ký ngay
              </Link>
            </p>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;
