import React from 'react';

const AboutUs = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-4xl font-bold text-center text-blue-700 mb-6">Về chúng tôi - Báo Mới Tây Bắc</h1>
      <p className="text-lg text-gray-700 mb-4">
        Chào mừng bạn đến với <strong>Báo Mới Tây Bắc</strong> – trang tin tức tổng hợp chuyên cập nhật những tin tức
        mới nhất từ khắp mọi nơi và đặc biệt là vùng Tây Bắc Việt Nam. Chúng tôi mang đến cho bạn cái nhìn toàn diện về các
        lĩnh vực từ thời sự, giải trí, pháp luật, văn hóa, xã hội và nhiều hơn nữa.
      </p>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-blue-600 mb-2">Sứ mệnh</h2>
        <p className="text-gray-700">
          Báo Mới Tây Bắc cam kết cung cấp thông tin trung thực, khách quan và kịp thời để giúp bạn luôn được cập nhật.
          Chúng tôi không chỉ là một trang báo điện tử mà còn là người bạn đồng hành cùng bạn khám phá Tây Bắc và thế giới.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-blue-600 mb-2">Giá trị cốt lõi</h2>
        <p className="text-gray-700">Trung thực – Khách quan – Tin cậy.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-blue-600 mb-2">Tại sao chọn chúng tôi</h2>
        <p className="text-gray-700">
          Chúng tôi cung cấp tin tức tổng hợp đa dạng, chất lượng cao từ nhiều lĩnh vực. Với đội ngũ biên tập viên giàu
          kinh nghiệm và đam mê, chúng tôi cam kết mang đến cho bạn thông tin nhanh chóng và chính xác.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-blue-600 mb-2">Danh mục tin tức</h2>
        <p className="text-gray-700">
          Bạn có thể dễ dàng tìm thấy các tin tức theo chủ đề yêu thích như: Thời sự, Giải trí, Pháp luật, Văn hóa, Xã hội,
          đặc biệt là các tin tức nổi bật về Tây Bắc.
        </p>
      </section>

      <p className="text-center text-gray-500 mt-8">
        <em>© {new Date().getFullYear()} Báo Mới Tây Bắc - baomoitaybac.com</em>
      </p>
    </div>
  );
};

export default AboutUs;
