// AddNews.jsx
import React, { useState } from 'react';
import axios from 'axios';

const AddNews = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault(); // Ngăn chặn reload trang
        try {
            const response = await axios.post('http://api.baomoitaybac.com/api/news', { title, content });
            console.log('Tin tức đã được thêm:', response.data);
            // Có thể chuyển hướng về trang danh sách tin tức hoặc thông báo thành công
        } catch (error) {
            console.error("Lỗi khi thêm tin tức", error);
        }
    };

    return (
        <div>
            <h1>Thêm Tin Tức Mới</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Tiêu đề:</label>
                    <input 
                        type="text" 
                        value={title} 
                        onChange={(e) => setTitle(e.target.value)} 
                        required 
                    />
                </div>
                <div>
                    <label>Nội dung:</label>
                    <textarea 
                        value={content} 
                        onChange={(e) => setContent(e.target.value)} 
                        required 
                    />
                </div>
                <button type="submit">Thêm</button>
            </form>
        </div>
    );
};

export default AddNews;
