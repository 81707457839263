import React, { useState, useRef, useEffect } from "react";
import { Link } from 'react-router-dom';


const Header = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const userMenuRef = useRef(null);

  const toggleNavbar = () => setIsNavbarOpen((prev) => !prev);
  const toggleUserMenu = () => setIsUserMenuOpen((prev) => !prev);

  // Đóng menu khi click ra ngoài
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setIsUserMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <nav className="shadow border-gray-200 dark:bg-gray-900 dark:border-gray-700 border-b">
      <div className="max-w-screen-xl flex items-center justify-end mx-auto px-4 py-1">
        {/* Logo */}
        

        {/* Toggle button (Mobile) */}
        <button
          onClick={toggleNavbar}
          className="inline-flex items-center p-2 w-10 h-10 text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>

        {/* Navbar Content */}
        <div
          className={`${
            isNavbarOpen ? "block" : "hidden"
          } w-full md:block md:w-auto`}
        >
          <ul className="flex flex-col font-medium p-4 md:flex-row md:space-x-8 md:p-0">
            <li>
              <Link
                to="/"
                className="block py-2 px-3 text-blue-700 md:text-blue-500 dark:text-white"
              >
                Home
              </Link>
            </li>

            {/* User Menu */}
            <li className="relative" ref={userMenuRef}>
              <button
                onClick={toggleUserMenu}
                className="flex items-center space-x-2 py-2 px-3 text-gray-900 hover:bg-gray-100 md:border-0 md:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700"
              >
                <img
                  src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                  alt="User avatar"
                  className="w-8 h-8 rounded-full"
                />
                <span>User</span>
                <svg
                  className="w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              {/* Dropdown Content */}
              <div
                className={`${
                  isUserMenuOpen ? "block" : "hidden"
                } absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg dark:bg-gray-700`}
              >
                <ul className="py-2">
                  <li>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600"
                    >
                      Profile
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600"
                    >
                      Settings
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600"
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
