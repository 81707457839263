// EditNews.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const EditNews = () => {
    const { id } = useParams(); // Lấy ID từ URL
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await axios.get(`http://api.baomoitaybac.com/api/news/${id}`); // Cập nhật URL API đúng
                setTitle(response.data.title);
                setContent(response.data.content);
            } catch (error) {
                console.error("Lỗi khi lấy tin tức", error);
            }
        };

        fetchNews();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`http://api.baomoitaybac.com/api/news/${id}`, { title, content });
            console.log('Tin tức đã được cập nhật:', response.data);
            // Có thể chuyển hướng về trang danh sách tin tức hoặc thông báo thành công
        } catch (error) {
            console.error("Lỗi khi cập nhật tin tức", error);
        }
    };

    return (
        <div>
            <h1>Chỉnh Sửa Tin Tức</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Tiêu đề:</label>
                    <input 
                        type="text" 
                        value={title} 
                        onChange={(e) => setTitle(e.target.value)} 
                        required 
                    />
                </div>
                <div>
                    <label>Nội dung:</label>
                    <textarea 
                        value={content} 
                        onChange={(e) => setContent(e.target.value)} 
                        required 
                    />
                </div>
                <button type="submit">Cập Nhật</button>
            </form>
        </div>
    );
};

export default EditNews;
